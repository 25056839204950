@import '../../../../../../estee_base/scss/theme-bootstrap';
@import '../../../../scss/sections/variable';

.custom-collection {
  .mpp-container {
    &:empty {
      display: none;
    }
  }
  .product-grid {
    clear: both;
    margin: 0 auto;
    max-width: 1066px;
    overflow: hidden;
    position: relative;
  }
  .product-grid__content {
    @media (min-width: $tablet-width-min) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -20px;
    }
    @media #{$medium-up} {
      margin: 0 -40px;
    }
  }
  .product-grid__item {
    color: #{$color-navy};
    margin: 0 0 35px;
    .product-brief {
      width: 100%;
      &__image {
        width: 38%;
        @media (min-width: $tablet-width-min) {
          width: 100%;
        }
        float: left;
        height: auto;
        background: none;
        margin-right: 20px;
        padding-bottom: 0;
      }
      &__info {
        text-align: left;
        @media (min-width: $tablet-width-min) {
          text-align: center;
        }
      }
    }
    @media (min-width: $tablet-width-min) {
      margin: 0 20px 100px;
      position: relative;
      text-align: center;
      width: calc(33.3333334% - 40px);
    }
    @media #{$medium-up} {
      margin-left: 40px;
      margin-right: 40px;
      width: calc(33.3333334% - 80px);
    }
  }
  .custom-collection-product-brief-v1 {
    @media (min-width: $tablet-width-min) {
      height: 100%;
    }
  }
  .product-brief__container {
    @media (min-width: $tablet-width-min) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 100%;
    }
  }
  .product-brief__info {
    overflow: hidden;
    @media (min-width: $tablet-width-min) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 100%;
    }
  }
  .product-brief__header-wrapper {
    @media (min-width: $tablet-width-min) {
      margin-bottom: 50px;
    }
  }
  .product-brief__flag {
    font-family: $akzidenz;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.1rem;
    margin-bottom: 3px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .product-brief__image-wrapper {
    float: left;
    margin: 0 30px 15px 0;
    width: 105px;
    @media (min-width: $tablet-width-min) {
      float: none;
      margin: 0 0 15px;
      width: auto;
    }
  }
  .product-brief__img {
    width: 100%;
  }
  .product-brief__title {
    font-family: $optimaregular, $optimalight;
    font-size: 1.125rem;
    letter-spacing: 0.1rem;
    line-height: 1.333333;
    margin: 0 0 4px;
    @media (min-width: $tablet-width-min) {
      font-size: 1.375rem;
      letter-spacing: normal;
      line-height: 1.227273;
    }
  }
  .product-brief__subtitle {
    font-family: $optimaregular, $optimalight;
    font-size: 0.8125rem;
    letter-spacing: 0.1rem;
    line-height: 1.230769;
    margin: 0 0 25px;
    @media (min-width: $tablet-width-min) {
      font-size: 1rem;
      letter-spacing: normal;
      line-height: 1.25;
      margin: 0;
    }
  }
  .product-brief__price {
    font-weight: 700;
    margin: 0 0 30px;
    @media (min-width: $tablet-width-min) {
      margin: 0;
    }
  }
  .product-brief__footer {
    @media (min-width: $tablet-width-min) {
      margin-top: auto;
    }
  }
  .product-brief__desc1 {
    font-size: 0.75rem;
    line-height: 1.333333;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .product_brief__reviews {
    margin-bottom: 30px;
    @media (min-width: $tablet-width-min) {
      margin-bottom: 10px;
    }
  }
  .custom-collection-quickshop-link {
    display: block;
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
    line-height: 1.25;
    margin-bottom: 30px;
    text-decoration: underline;
    text-transform: uppercase;
    @media (min-width: $tablet-width-min) {
      margin-bottom: 15px;
    }
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .product-brief__ctas .button {
    border: 1px solid #{$color-navy};
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 700;
    height: 33px;
    letter-spacing: 0.15em;
    line-height: 2.83;
    text-align: center;
    text-transform: uppercase;
    max-width: 200px;
    width: 100%;
    @media (min-width: $tablet-width-min) {
      min-width: 196px;
      max-width: none;
      width: auto;
    }
    &:focus {
      background-color: #{$color-navy};
      color: #{$color-white};
    }
  }
}

.custom-collection-load-more {
  margin-bottom: 15px;
}

.button--custom-collection-load-more {
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  line-height: 1.25;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
